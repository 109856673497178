/* navigation */
.header {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 7px 0px;
  height: 85px;
  /* background-color: rgba(0,0,0,0.69); */
  /* background-color: #61696c; */
  /* background-color: rgba(4, 89, 89, 0.6); */
  background-color: #61696c;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .header {
    height: 70px;
  }
  .logo img {
    height: 70px !important;
  }
}
.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.menu_mobile {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  height: 100vh;
  width: 210px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  margin-right: -210px;
  transition: 0.5s;
}

.menu_mobile.open {
  height: 100vh;
  width: 210px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  margin-right: 0;
  transition: 0.5s;
}
.menu_mobile_relative {
  position: relative;
  width: 100%;
  height: 100%;
}
.menu_mobile_overlay {
  background-color: #61696c;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.menu_mobile_items {
  position: relative;
  list-style: none;
  padding: unset;
  padding-top: 84px;
}
.menu_mobile_items li {
  text-transform: uppercase;
  padding: 30px 20px;
  text-align: center;
  transition: 0.5s;
  font-family: "Philosopher", sans-serif;
}
.menu_mobile_items li:hover {
  color: white;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  transition: 0.5s;
  margin-top: -10px;
}
.menu_mobile_items li a {
  color: white;
  text-decoration: none;
}
.menu {
  display: none;
  /* font-family: "PT Sans", sans-serif; */
  font-family: "Philosopher", sans-serif;
}
.menu a {
  color: white;
  margin: 0 16px;
  text-decoration: none;
  transition: 1s;
}
.menu a:hover {
  color: #ffde93;
  transition: 1s;
}
.hamburger {
  display: block;
  position: relative;
  z-index: 1;
  user-select: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  margin-right: 15px;
}
.hamburger span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background-color: white;
  border-radius: 6px;
  z-index: 1;
  transform-origin: 0 0;
  transition: 0.4s;
}
.hamburger:hover span:nth-child(2) {
  transform: translateX(10px);
  background-color: white;
  /* background-color: #f01887; */
  /* background-color: #61696c; */
}
.hamburger.is-active span:nth-child(1) {
  transform: translate(0px, -2px) rotate(45deg);
}
.hamburger.is-active span:nth-child(2) {
  opacity: 0;
  transform: translateX(15px);
}
.hamburger.is-active span:nth-child(3) {
  transform: translate(-3px, 3px) rotate(-45deg);
}
.hamburger.is-active:hover span {
  background-color: white;
  /* background-color: #f01887; */
  /* background-color: #61696c; */
}
@media screen and (min-width: 768px) {
  .hamburger {
    display: none;
  }
  .menu {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 922px) {
  .header_wrapper {
    max-width: 1240px;
  }
}
.logo {
  max-width: 220px;
  margin-left: 15px;
}
.logo img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .logo {
    display: flex;
  }
  .logo img {
    width: auto;
    height: 100px;
  }
}
.number {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 155px;
  margin-left: 20px;
  margin-right: 15px;
}
.number img {
  max-width: 30px;
  margin-right: 10px;
}
.phone_number {
  color: #c9c9c9;
  transition: 1s;
}
.phone_number:hover {
  /* color: #f01887; */
  color: #61696c;
  transition: 1s;
}

/* end of nav */

/* banner section  */

.banner_wrapper {
  background-image: url("./images/banner.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 767px) {
  .banner_wrapper {
    padding: 150px 40px 80px 40px;
  }
  .text_overlay {
    max-width: 767px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
  }
  .text_overlay_address {
    font-family: "Philosopher", sans-serif;
    width: 100%;
    /* color: #f01887; */
    color: #61696c;
  }
  .text_overlay_title {
    font-family: "Philosopher", sans-serif;
    color: white;
    font-size: 40px;
    margin-top: 0;
  }
  .text_overlay_subtitle {
    font-family: "PT Sans", sans-serif;
    color: #d7d7d7;
    margin-bottom: 10px;
  }
  .text_overlay_button_container a {
    font-family: "PT Sans", sans-serif;
    text-align: center;
    font-size: 15px;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    margin: 10px 0;
  }
  .text_overlay_button_container a:nth-child(1) {
    /* border-color: #ee3a5e; */
    border-color: #61696c;
    background-color: #61696c;
    padding: 17px 30px;
    border-radius: 7px;
  }
  .text_overlay_button_container a:nth-child(2) {
    padding: 17px 30px;
    border: 2px solid #ffffff;
    border-radius: 7px;
  }
  .text_overlay_button_container {
    width: 100%;
    display: flex;
    flex-flow: column;
  }
}
@media screen and (min-width: 768px) and (max-width: 1139px) {
  .text_overlay_wrapper {
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
  .text_overlay_wrapper div:nth-child(1) {
    flex: 2;
  }
  .text_overlay_wrapper div:nth-child(2) {
    flex: 1;
  }
  .banner_wrapper {
    padding: 120px 50px 120px 50px;
    padding-left: 15px;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
  }
  .text_overlay {
    max-width: 767px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
  }
  .text_overlay_address {
    font-family: "Philosopher", sans-serif;
    width: 100%;
    /* color: #f01887; */
    color: #61696c;
  }
  .text_overlay_title {
    font-family: "Philosopher", sans-serif;
    color: white;
    font-size: 48px;
    margin-top: 0;
  }
  .text_overlay_subtitle {
    font-family: "PT Sans", sans-serif;
    color: #d7d7d7;
    margin-bottom: 10px;
  }
  .text_overlay_button_container a {
    font-family: "PT Sans", sans-serif;
    text-align: center;
    font-size: 15px;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    margin: 10px 0;
  }
  .text_overlay_button_container a:nth-child(1) {
    /* border-color: #ee3a5e;
    background-color: #ee3a5e; */
    border-color: #61696c;
    background-color: #61696c;
    padding: 17px 30px;
    border-radius: 7px;
  }
  .text_overlay_button_container a:nth-child(2) {
    padding: 17px 30px;
    border: 2px solid #ffffff;
    border-radius: 7px;
  }
  .text_overlay_button_container {
    width: 100%;
    display: flex;
    flex-flow: column;
  }
}
@media screen and (min-width: 1140px) {
  .text_overlay_wrapper {
    display: flex;
    max-width: 1240px;
    margin: 0 auto;
    transform: translateX(15px);
  }
  .text_overlay_wrapper div:nth-child(1) {
    flex: 2;
  }
  .text_overlay_wrapper div:nth-child(2) {
    flex: 1;
  }
  .banner_wrapper {
    padding: 225px 0px 225px 0px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: relative;
  }
  .text_overlay {
    max-width: 767px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
  }
  .text_overlay_address {
    font-family: "Philosopher", sans-serif;
    width: 100%;
    /* color: #f01887; */
    color: #61696c;
  }
  .text_overlay_title {
    font-family: "Philosopher", sans-serif;
    color: white;
    font-size: 55px;
    margin-top: 0;
  }
  .text_overlay_subtitle {
    font-family: "PT Sans", sans-serif;
    color: #d7d7d7;
    margin-bottom: 10px;
  }
  .text_overlay_button_container a {
    font-family: "PT Sans", sans-serif;
    text-align: center;
    font-size: 15px;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    margin-top: 20px;
    margin-right: 10px;
  }
  .text_overlay_button_container a:nth-child(1) {
    /* border-color: #ee3a5e;
    background-color: #ee3a5e; */
    border-color: #61696c;
    background-color: #61696c;
    padding: 17px 30px;
    border-radius: 7px;
  }
  .text_overlay_button_container a:nth-child(2) {
    padding: 17px 30px;
    border: 2px solid #ffffff;
    border-radius: 7px;
  }
  .text_overlay_button_container {
    width: 100%;
    display: flex;
  }
}
.text_overlay_button_container a:nth-child(2) {
  transition: 0.5s;
}
.text_overlay_button_container a:nth-child(2):hover {
  background-color: white;
  /* color: #f01887; */
  color: #61696c;
  transition: 0.5s;
}

.background-overlay {
  background-color: #121315;
  opacity: 0.49;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.banner_wrapper {
  transform: translateZ(-10px);
}

/* end of banner section */

.element3 {
  height: 500px;
  background-color: white;
  width: 100vw;
  position: relative;
}

.custom-shape-divider-top-1678372368 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  transform: rotate(180deg);
  line-height: 0;
}

.custom-shape-divider-top-1678372368 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 26px;
}

.custom-shape-divider-top-1678372368 .shape-fill {
  fill: #ffffff;
}
/* about section  */

.about_wrapper {
  background-color: white;
  height: auto;
  max-width: 1440px;
}
/* * {
  border: 1px solid red;
} */
@media screen and (max-width: 768px) {
  .about_wrapper {
    padding: 50px 25px 60px 25px;
  }
  .about_gallery_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .about_gallery_item {
    width: 48%;
    margin: 0 auto;
    height: 400px;
  }
  .about_gallery_item_inner {
    border-radius: 20px;
    width: 95%;
    margin: 0 auto;
    height: 96%;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .about_gallery_item:nth-child(2),
  .about_gallery_item:nth-child(4) {
    transform: translateY(40px);
  }
  .about_us_text {
    margin-top: 50px;
  }
  .about_us_text div:nth-child(1) {
    text-align: center;
  }
  .about_us_text h2 {
    font-family: "Philosopher", sans-serif;
    color: #61696c;
    text-align: center;
  }
  .about_us_text p {
    text-align: center;
    font-family: "PT Sans", sans-serif;
    font-weight: 300;
    line-height: 1.7rem;
    color: #3e4359;
  }
}
@media screen and (min-width: 768px) {
  .about_wrapper {
    padding: 60px 25px 120px 25px;
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
  }
  .about_gallery_wrapper {
    width: 50%;
    height: 400px;
    display: flex;
    flex-flow: row;
  }
  .about_gallery_item {
    width: 48%;
    margin: 0 auto;
    height: 400px;
  }
  .about_gallery_item_inner {
    border-radius: 20px;
    width: 95%;
    margin: 0 auto;
    height: 96%;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .about_gallery_item:nth-child(2),
  .about_gallery_item:nth-child(4) {
    transform: translateY(50px);
  }
  .about_gallery_item:nth-child(2) {
    margin-left: 8px;
    margin-right: 8px;
  }
  .about_gallery_item:nth-child(4) {
    margin-left: 8px;
  }
  .about_us_text {
    width: 40%;
  }
  .about_us_text div:nth-child(1) {
    text-align: left;
  }
  .about_us_text h2 {
    font-family: "Philosopher", sans-serif;
    color: #61696c;
    text-align: left;
    font-size: 34px;
  }
  .about_us_text p {
    text-align: left;
    font-family: "PT Sans", sans-serif;
    font-weight: 300;
    color: #3e4359;
    font-size: 17px;
    line-height: 1.7rem;
  }
}

/* price section */

.price_section_wrapper {
  width: 100%;
  height: auto;
}
.discount_banner {
  padding: 50px 20px 100px 20px;
  background-image: url(https://images.unsplash.com/photo-1616105996583-f9e3c00bb31f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  /* background-image: url(https://dev.smartinggoods.com/beautynails/wp-content/uploads/sites/36/2020/05/GlitterBG-free-img.jpg); */
  background-position: center center;
  background-repeat: repeat;
  position: relative;
}
.discount_banner_overlay {
  /* background-color: #FF4B6F; */
  background-color: #61696c;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.price_text_section {
  margin: 0 auto;
  z-index: 10;
  position: relative;
}
.price_image_wrapper {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.price_text_section h2 {
  font-family: "Philosopher", sans-serif;
  text-align: center;
  color: white;
}
.price_text_section p {
  color: #ffffff;
  font-weight: 300;
  font-family: "PT Sans", sans-serif;
  font-size: 13px;
  text-align: center;
}
.price_overlay_button_wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.price_overlay_button_wrapper a {
  padding: 17px 30px;
  border: 2px solid #ffffff;
  border-radius: 7px;
  color: white;
  text-decoration: none;
  text-transform: capitalize;
}
.discount_overlap_title {
  padding: 20px 20px 20px 20px;
  /* background-image: url(https://dev.smartinggoods.com/beautynails/wp-content/uploads/sites/36/2020/05/pricing-bg.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80%;
  position: relative;
  border-radius: 30px;
  margin: 0 auto;
  /* transform: translateY(-75px); */
  margin-top: -75px;
}
.discount_overlap_title h2 {
  font-family: "Philosopher", sans-serif;
  color: white;
  text-align: center;
  z-index: 1;
  position: relative;
  margin-bottom: 0;
}
.discount_overlap_title p {
  margin-top: 5px;
  color: #ffffff;
  font-weight: 300;
  font-family: "PT Sans", sans-serif;
  font-size: 13px;
  text-align: center;
  z-index: 1;
  position: relative;
}
.discount_title_overlay {
  background-color: #142233;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 30px;
}
.prices_flex {
  display: flex;
  flex-flow: column;
  padding: 10px 10px;
  background-color: #fefefe;
  margin-top: 50px;
}
.price_item {
  background-color: white;
  width: 75%;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0px 5px 35px 0px rgb(84 89 95 / 10%);
  padding: 40px 30px 40px 30px !important;
  margin-bottom: 20px;
}
.price_item h2 {
  font-family: "Philosopher", sans-serif !important;
  position: relative;
  margin-bottom: 70px;
  /* color: #FF4B6F; */
  color: #61696c;
}
.underline_price {
  position: absolute;
  width: 50px;
  height: 2px;
  border-radius: 40px;
  /* background-color: #FF4B6F; */
  background-color: #61696c;
  bottom: -20px;
}
.prices {
  margin-bottom: 20px;
  line-height: 1.2em;
  font-size: 15.5px;
  font-family: "Philosopher", sans-serif;
}
.price_block {
  /* color: #FF4B6F; */
  color: #61696c;
  font-family: "Philosopher", sans-serif;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .prices_flex {
    flex-flow: row;
    flex-wrap: wrap;
  }
  .price_item {
    width: 40%;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1000px) {
  .prices_flex {
    flex-flow: row;
    flex-wrap: wrap;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
  }
  .price_item {
    /* width: 19%; */
    /* width: 19.5%; */
    flex: 1;
    margin: 10px;
  }
}

/* end of price section */
.insta_container {
  padding: 40px 20px;
  max-width: 1140px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.insta_innner_container {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.insta_innner_container h1,
.insta_innner_container p {
  text-align: center;
}
.insta_innner_container p {
  font-family: "PT Sans", sans-serif;
}
.insta_innner_container h1 {
  /* color: #FF4B6F; */
  color: #61696c;
  font-family: "Philosopher", sans-serif;
  margin-bottom: -5px;
}
.feed_container {
  min-height: 450px;
  background: #e5e5e5;
  padding: 50px 30px 0px 30px;
  width: 90%;
  display: flex;
  position: relative;
  overflow-y: hidden;
  overflow-x: scroll;
}
.feed_container article {
  margin-right: 20px;
}
.feed_container article:nth-child(3) {
  margin-right: 20px;
}
div.feed_container > article:nth-child(6) {
  margin-right: 0px !important;
}

.feed_container article {
  width: calc(100% / 4);
  min-width: 200px;
}
@media screen and (max-width: 1000px) {
  .feed_container {
    min-height: 370px;
  }

  .feed_container article {
    border-radius: 0 0 10px 10px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 22%);
    height: 350px !important;
    position: relative;
    margin-right: 20px;
  }

  .insta_text_wrapper {
    border-radius: unset !important;
    background: unset !important;
    box-shadow: unset !important;
    height: 150px !important;
    min-height: unset !important;
    overflow: scroll;
  }
  .insta_logo_wrapper {
    position: absolute;
    bottom: 5px;
  }
  .business_name {
    font-size: 1.2rem !important;
  }
  .insta_user_data {
    font-size: 14px;
  }
  .show_more_insta {
    padding: 30px 30px !important;
  }
}
.insta_image_wrapper {
  width: 100%;
  position: relative;
}
.insta_image_wrapper img {
  width: 100%;
  height: 200px;
}
.insta_absolute {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: "Philosopher", sans-serif;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #405de6;
  padding: 5px 5px;
}
.insta_text_wrapper {
  padding: 7%;
  min-height: 200px;
  font-size: 14px;
  line-height: 20px;
  background-color: white;
  margin-top: -5px;
  border-radius: 0 0 10px 10px;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 22%);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.insta_logo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url(https://scontent.xx.fbcdn.net/v/t51.2885-15/51457483_781422048886823_975724580041654272_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=86c713&_nc_ohc=Lmb2Ggcn4gYAX_L69dh&_nc_ht=scontent.xx&edm=AL-3X8kEAAAA&oh=00_AfCmQuGmQjPS9F9ppuvKTuNRxGxUn5UTOuw2ILZ_UflxYA&oe=640ED6B8);
}

.insta_logo_wrapper {
  display: flex;
  flex-flow: row;
}
.business_name {
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  padding: 3px 0 0 15px;
  text-align: left;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Philosopher", sans-serif;
  font-size: 1.4rem;
}
.insta_user_data {
  padding: 3px 0 0 15px;
}

.show_more_insta {
  background: #e5e5e5;
  width: 90%;
  padding: 40px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show_more_insta a {
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  background: #cdcdcd;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px 40px;
}

/* footer section */
.footer_wrapper {
  /* background: #1b202a; */

  background-color: #61696c;
  min-height: 400px;
  padding: 60px 30px 20px 30px;
  border-bottom: 1px solid #484545;
}
.copyright {
  font-family: "PT Sans", sans-serif;
  padding: 30px 10px;
  font-size: 12px;
}
@media screen and (max-width: 767px) {
  .footer {
    display: none;
  }
  .footer_mobile_title {
    /* color: #f01887; */
    color: white;
    font-family: "Philosopher", sans-serif;
    font-size: 1.5rem;
  }
  .mobile_footer ul {
    list-style: none;
    padding: 0;
  }
  .mobile_footer ul li a {
    color: white;
    line-height: 2rem;
    font-family: "PT Sans", sans-serif;
    font-size: 0.9rem;
  }

  .socials_footer_mobile {
    display: flex;
    list-style: none;
  }
  .socials_footer_mobile li {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: 1px solid white; */
    margin-right: 10px;
  }
}
@media screen and (min-width: 768px) {
  .footer_main {
    width: 100%;
    /* background: #1b202a; */
    background-color: #61696c;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer_wrapper {
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 20px !important;
  }
  .mobile_footer {
    display: none;
  }
  .footer {
    display: flex;
  }
  .footer_left {
    flex: 1.8;
  }
  .footer_right {
    flex: 1;
  }
  .footer_mobile_title {
    /* color: #f01887; */
    color: white;
    font-family: "Philosopher", sans-serif;
    font-size: 1.5rem;
  }
  .footer ul {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
  }
  .footer ul li a {
    color: white;
    line-height: 2rem;
    font-family: "PT Sans", sans-serif;
    font-size: 0.9rem;
  }
  .socials_footer_mobile {
    display: flex;
    list-style: none;
  }
  .socials_footer_mobile li {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: 1px solid white; */
    margin-right: 10px;
  }
}
.social_icon img {
  height: 40px;
  border-radius: 50%;
  width: 40px;
}
.contact_container {
  margin: 0 auto;
  max-width: 1125px;
  display: flex;
  min-height: 50vh;
  padding: 40px;
  justify-content: center;
  align-items: center;
}
.contact_form {
  width: 350px;
  margin: 0 auto;
  padding: 20px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.contact_form div {
  margin-bottom: 20px;
}
.contact_form h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: "Philosopher", sans-serif;
  font-size: 2.5rem;
}
.opening_hours {
  text-align: center;
  padding-bottom: 20px;
}
.contact_top_img {
  background-image: url(./images/contact2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 180px 0px 120px 0px;
  position: relative;
}
.contact_top_img h2 {
  z-index: 10;
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-family: "Philosopher", sans-serif;
  color: white;
  font-size: 2.5rem;
}
.contact_top_img div:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.7;
  width: 100%;
  height: 100%;
}
.contact_container form {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.contact_container form div {
  display: flex;
  justify-content: center;
}
.contact_container div input {
  width: 90%;
  padding: 15px 10px;
  color: #666;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
.contact_container div select {
  width: 96%;
  padding: 15px 10px;
  color: #666;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
}
.contact_container div textarea {
  width: 90%;
  padding: 15px 10px;
  color: #666;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  border-radius: 5px;
  height: 100px;
  flex-flow: column;
}
.contact_container button {
  width: 50%;
  padding: 17px 30px;
  border: 2px solid #ffffff;
  border-radius: 7px;
  font-family: "PT Sans", sans-serif;
  text-align: center;
  font-size: 15px;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #61696c;
  color: white;
  text-decoration: none;
  margin: 0 auto;
}

.contact_loading,
.contact_loaded {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.text_overlay {
  margin-left: unset;
  margin-right: unset;
}

.menu a:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .contact_container {
    flex-flow: column;
  }
}
.contact_container {
  display: flex;
  gap: 20px;
}
